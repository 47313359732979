import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppProvider } from './components/providers';
import UIFooter from './components/footer';
import UINavbar from './components/navbar';
import { UIStartupOverlay } from './components/overlays';
import C from './constants';

import AboutView from './views/about';
import ContactView from './views/contact';
import CustomView from './views/custom';
import HomeView from './views/home';

const App = () => (
  <AppProvider>
    <UIStartupOverlay>
      <UINavbar />
      <Routes>
        <Route
          index
          element={<HomeView />}
        />
        <Route
          path={C.ROUTE_PATH_CUSTOM}
          element={<CustomView />}
        />
        <Route
          path={C.ROUTE_PATH_ABOUT}
          element={<AboutView />}
        />
        <Route
          path={C.ROUTE_PATH_CONTACT}
          element={<ContactView />}
        />
      </Routes>
      <UIFooter />
    </UIStartupOverlay>
  </AppProvider>
);

export default App;
