import React, { createContext } from 'react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { DarkTheme, LightTheme } from '../../theme';

export const ThemeContext = createContext(DarkTheme);

const ThemeProvider: React.FC<{
    darkMode: boolean;
}> = ({ darkMode, children }) => (
  <ThemeContext.Provider value={darkMode ? DarkTheme : LightTheme}>
    <MuiThemeProvider theme={darkMode ? DarkTheme : LightTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  </ThemeContext.Provider>
);

export default ThemeProvider;
