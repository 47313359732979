import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Drawer, IconButton } from '@mui/material';
import {
  MenuIcon, SVGizedsystemsABLogoDark, SVGizedsystemsABLogoLight, SVGizedsystemsABSquareLogo,
} from '../icons';
import { useIsMobile } from '../../hooks';
import C from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar,
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  logoContainer: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: 0,
      padding: theme.spacing(2),
    },
  },
  logo: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(10),
      height: 'auto',
    },
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  menuButtonContainer: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(1),
  },
  linksContainer: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  link: {
    margin: theme.spacing(2, 4),
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '20px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color 0.15s ease-in-out',
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  activeLink: {
    position: 'relative',
    color: theme.palette.primary.main,
    cursor: 'default',
    pointerEvents: 'none',
  },
  activeLinkBar: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    width: 2,
    height: '70%',
    left: theme.spacing(1.5),
    transition: 'top 0.25s ease-in-out, height 0.25s ease-in-out',
    [theme.breakpoints.up('md')]: {
      height: 2,
      bottom: theme.spacing(1.5),
      transition: 'left 0.25s ease-in-out, width 0.25s ease-in-out',
    },
  },
}));

interface NavLinkProps {
  label: ReactNode;
  path: string;
  onClick: () => void;
  anchorEl: (anchorEl: HTMLAnchorElement) => void;
}

const NavLink: React.FC<NavLinkProps> = (props) => {
  const {
    label,
    path,
    onClick,
    anchorEl,
  } = props;
  const classes = useStyles();
  const location = useLocation();

  return path !== location.pathname ? (
    <Link
      className={classes.link}
      to={path}
      onClick={onClick}
    >
      {label}
    </Link>
  ) : (
    <a
      ref={anchorEl}
      className={clsx(classes.link, classes.activeLink)}
      href={path}
    >
      {label}
    </a>
  );
};

const UINavbar: React.FC = () => {
  const [showBg, setShowBg] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeNavLinkEl, setActiveNavLinkEl] = useState<HTMLAnchorElement | null>(null);

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleScroll = () => {
      setShowBg(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setDrawerOpen(false);
    }
  }, [isMobile]);

  const handleOpenDrawer = useCallback(() => setDrawerOpen(true), []);
  const handleCloseDrawer = useCallback(() => setDrawerOpen(false), []);

  const handleCloseDrawerSlow = useCallback(() => {
    setTimeout(() => setDrawerOpen(false), 250);
  }, []);

  const links = (
    <>
      <NavLink
        label="Hem"
        path={C.ROUTE_PATH_HOME}
        onClick={handleCloseDrawerSlow}
        anchorEl={setActiveNavLinkEl}
      />
      <NavLink
        label="Skräddarsytt"
        path={C.ROUTE_PATH_CUSTOM}
        onClick={handleCloseDrawerSlow}
        anchorEl={setActiveNavLinkEl}
      />
      <NavLink
        label="Om oss"
        path={C.ROUTE_PATH_ABOUT}
        onClick={handleCloseDrawerSlow}
        anchorEl={setActiveNavLinkEl}
      />
      <NavLink
        label="Kontakt"
        path={C.ROUTE_PATH_CONTACT}
        onClick={handleCloseDrawerSlow}
        anchorEl={setActiveNavLinkEl}
      />
    </>
  );

  const activeLinkBar = activeNavLinkEl !== null && (
    <div
      className={classes.activeLinkBar}
      style={(
        isMobile ? {
          top: activeNavLinkEl.offsetTop,
          height: activeNavLinkEl.clientHeight,
        } : {
          left: activeNavLinkEl.offsetLeft,
          width: activeNavLinkEl.clientWidth,
        }
      )}
    />
  );

  const linksContainer = (
    isMobile ? (
      <Drawer
        className={classes.linksContainer}
        anchor="right"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {links}
        {activeLinkBar}
      </Drawer>
    ) : (
      <nav className={classes.linksContainer}>
        {links}
        {activeLinkBar}
      </nav>
    )
  );

  let logo = null;
  if (isMobile) {
    logo = <SVGizedsystemsABSquareLogo className={classes.logo} />;
  } else {
    logo = theme.palette.mode === 'dark'
      ? <SVGizedsystemsABLogoDark className={classes.logo} />
      : <SVGizedsystemsABLogoLight className={classes.logo} />;
  }

  return (
    <Box
      component="nav"
      className={classes.root}
      sx={{
        bgcolor: showBg || isMobile ? 'background.paper' : 'transparent',
        boxShadow: showBg || isMobile ? 4 : 0,
      }}
    >
      <div className={classes.logoContainer}>{logo}</div>
      {linksContainer}
      <div className={classes.menuButtonContainer}>
        {
          isMobile && (
            <IconButton
              color="primary"
              size="small"
              onClick={handleOpenDrawer}
            >
              <MenuIcon />
            </IconButton>
          )
        }
      </div>
    </Box>
  );
};

export default UINavbar;
