import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
import { UIButton } from '../../components/buttons';
import C from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(6),
    },
    [theme.breakpoints.up('xl')]: {
      gap: theme.spacing(12),
    },
  },
  paperContainer: {
    width: '90%',
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-start',
      width: '40%',
      paddingTop: theme.spacing(1),
      transition: 'padding-top 0.25s ease-in-out, padding-bottom 0.25s ease-in-out',
      '& > .MuiPaper-root > div:last-child': {
        marginTop: 0,
        transition: 'margin-top 0.25s ease-in-out',
      },
      '&:hover': {
        paddingTop: 0,
        '& > .MuiPaper-root > div:last-child': {
          marginTop: theme.spacing(1),
        },
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },
  },
  paper: {
    padding: theme.spacing(2),
    animation: 'home__core-values-card--fade-in 0.75s 0.25s both cubic-bezier(0, 0, 0.4, 1)',
  },
  highlightedText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const CoreValuesCard: React.FC<{
    title: ReactNode;
    description: ReactNode;
    actions?: ReactNode;
}> = ({ title, description, actions }) => {
  const classes = useStyles();

  return (
    <div className={classes.paperContainer}>
      <Paper
        className={classes.paper}
        elevation={4}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold' }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
        {
          actions && (
            <>
              <br />
              <div>
                {actions}
              </div>
            </>
          )
        }
      </Paper>
    </div>
  );
};

const CoreValuesSection: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <section className={classes.root}>
      <CoreValuesCard
        title="Skräddarsytt system"
        description={(
          <>
            Vi skapar ett
            <span className={classes.highlightedText}> modernt, snabbt och stabilt system </span>
            åt ditt företag, utefter er
            <span className={classes.highlightedText}> kravspecifikation - oavsett komplexitet, oavsett bransch</span>
            .
            <br />
            <br />
            Med
            <span className={classes.highlightedText}> år av erfarenhet </span>
            och
            <span className={classes.highlightedText}> djup förståelse för systemutveckling </span>
            så kommer erat
            <span className={classes.highlightedText}> drömsystem bli en verklighet</span>
            .
          </>
        )}
        actions={(
          <UIButton
            onClick={() => navigate(C.ROUTE_PATH_CUSTOM)}
          >
            Läs mer
          </UIButton>
        )}
      />
      <CoreValuesCard
        title="Snabbt och stabilt system, ännu snabbare support"
        description={(
          <>
            En av de ledande anledningarna till att företag byter system är på grund av dålig support.
            <br />
            <br />
            Vi erbjuder därför
            <span className={classes.highlightedText}> trevlig 24/7 support, snabb och stabil hosting samt blixtsnabb utbyggnad</span>
            .
            Skulle fel eller frågor uppstå är vi bara
            <span className={classes.highlightedText}> ett telefonsamtal bort</span>
            !
          </>
        )}
        actions={(
          <>
            <UIButton
              margin="right"
              onClick={() => navigate(C.ROUTE_PATH_ABOUT)}
            >
              Om oss
            </UIButton>
            <UIButton
              margin="left"
              onClick={() => navigate(C.ROUTE_PATH_CONTACT)}
            >
              Kontakt
            </UIButton>
          </>
        )}
      />
      <CoreValuesCard
        title="Bra kvalité till lågt pris"
        description={(
          <>
            <span className={classes.highlightedText}>Skippa byråkrati och alla onödiga kostnader</span>
            , vi går rakt på sak.
            Detta innebär även att vi har
            <span className={classes.highlightedText}> 110% </span>
            fokus på dig och ditt system, vilket
            <span className={classes.highlightedText}> sänker priset för dig</span>
            !
          </>
        )}
        actions={(
          <UIButton
            onClick={() => navigate(C.ROUTE_PATH_CUSTOM)}
          >
            Beställ idag
          </UIButton>
        )}
      />
    </section>
  );
};

export default CoreValuesSection;
