import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Paper, Typography } from '@mui/material';
import { SVGizedsystemsABSquareLogo } from 'src/components/icons';
import AvatarEmilEngelin from '../../assets/avatar-emil-engelin.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(6),
      marginTop: theme.spacing(16),
    },
    [theme.breakpoints.up('lg')]: {
      gap: theme.spacing(8),
    },
  },
  paper: {
    padding: theme.spacing(2),
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '30%',
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  papers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  avatar: {
    width: `${theme.spacing(8)} !important`,
    height: `${theme.spacing(8)} !important`,
  },
}));

const AboutView: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.papers}>
        <Paper
          elevation={4}
          className={classes.paper}
        >
          <div className={classes.header}>
            <SVGizedsystemsABSquareLogo className={classes.avatar} />
            <div>
              <Typography
                variant="h6"
                color="primary"
                sx={{ fontWeight: 'bold' }}
              >
                izedsystems AB
              </Typography>
              <Typography>
                IT-bolag, grundades 2022
              </Typography>
            </div>
          </div>
          <Typography variant="body1">
            izedsystems AB säljer systemlösningar åt företag som vill utöka sin försäljning,
            förbättra kundhantering, samt underlätta med beställningar och inventarie.
          </Typography>
        </Paper>
      </div>
      <div className={classes.papers}>
        <Typography variant="h5">Anställda</Typography>
        <Paper
          elevation={4}
          className={classes.paper}
        >
          <div className={classes.header}>
            <Avatar src={AvatarEmilEngelin} className={classes.avatar} />
            <div>
              <Typography
                variant="h6"
                color="primary"
                sx={{ fontWeight: 'bold' }}
              >
                Emil Engelin
              </Typography>
              <Typography>
                Systemutvecklare och försäljare
              </Typography>
            </div>
          </div>
          <Typography variant="body1" gutterBottom>
            Emil har byggt allt från transportsystem och kundsystem till lösningar för autonoma
            fordon åt diverse Svenska företag, bland annat Itancan Consulting AB, Miljövision AB och Sverige Expressen Åkeri AB.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Han har djup förståelse för både försäljning, kundrelationer samt programutveckling och är redo att skapa ditt drömsystem!
          </Typography>
          <br />
          <Typography variant="body2">
            <a href="mailto:emil@izedsystems.com" className={classes.link}>emil@izedsystems.com</a>
            <br />
            <a href="tel:+46 073 907 75 19" className={classes.link}>+46 073 907 75 19</a>
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

export default AboutView;
