import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography, TypographyProps } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    '&::after': {
      content: '""',
      position: 'absolute',
      width: theme.spacing(0.5),
      height: '70%',
      top: '50%',
      right: 0,
      transform: 'translate(0, -50%)',
      backgroundColor: theme.palette.primary.main,
      animation: 'blink-animation 0.75s steps(5, start) infinite',
    },
    '&::last-letter': {
      color: 'red',
    },
  },
}));

const getTextIdleDuration = (text: string): number => text.length * 150;

const WRITING_INTERVAL = 150;
const ERASING_INTERVAL = 125;
const EMPTY_DURATION = 2500;

const UIMutatingTypography: React.FC<TypographyProps & {
    texts: string[];
}> = ({ texts, className, ...props }) => {
  const [text, setText] = useState(texts[0]);
  const [displayed, setDisplayed] = useState('');

  useEffect(() => {
    let cursor = '';
    let waitingId: NodeJS.Timer;
    let erasingId: NodeJS.Timer;
    let emptyId: NodeJS.Timer;
    // Start writing
    const writingId = setInterval(() => {
      if (cursor.length < text.length) {
        cursor += text[cursor.length];
        setDisplayed(cursor);
      } else {
        clearInterval(writingId);
        // Wait
        waitingId = setTimeout(() => {
          // Start erasing
          erasingId = setInterval(() => {
            if (cursor.length > 0) {
              cursor = cursor.substring(0, cursor.length - 2);
              setDisplayed(cursor);
            } else {
              clearInterval(erasingId);

              // Leave empty for a moment
              emptyId = setTimeout(() => {
                const otherTexts = [...texts];
                otherTexts.splice(texts.indexOf(text), 1);
                setText(otherTexts[Math.round(Math.random() * (otherTexts.length - 1))]);
              }, EMPTY_DURATION);
            }
          }, ERASING_INTERVAL);
        }, getTextIdleDuration(text));
      }
    }, WRITING_INTERVAL);
    return () => {
      clearInterval(writingId);
      clearTimeout(waitingId);
      clearInterval(erasingId);
      clearTimeout(emptyId);
    };
  }, [text, texts]);

  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.root, className)}
      align="center"
      {...props}
    >
      {displayed || <>&nbsp;</>}
    </Typography>
  );
};

export default UIMutatingTypography;
