import React, { HTMLAttributes } from 'react';

// https://svg2jsx.com/

const SVGizedsystemsABLogoDark: React.FC<HTMLAttributes<HTMLOrSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1157.7"
    height="346.4"
    version="1.1"
    viewBox="0 0 1157.7 346.4"
    {...props}
  >
    <defs>
      <linearGradient id="linearGradient1834">
        <stop offset="0" stopColor="#3a67c5" stopOpacity="1" />
        <stop offset="1" stopColor="#533ac5" stopOpacity="0.992" />
      </linearGradient>
      <path
        id="rect1436-2-4"
        d="M602.857 557.143H1808.571V1494.286H602.857z"
      />
      <linearGradient
        id="linearGradient1875-7"
        x1="2440.367"
        x2="2722.756"
        y1="1842.676"
        y2="1560.287"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
        xlinkHref="#linearGradient1834"
      />
      <linearGradient
        id="linearGradient937"
        x1="2440.367"
        x2="2722.756"
        y1="1842.676"
        y2="1560.287"
        gradientTransform="rotate(180 3019.217 1669.476)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
        xlinkHref="#linearGradient1834"
      />
    </defs>
    <g transform="translate(-400.166 -862.6)">
      <g transform="translate(-2040.201 -633.676)">
        <g>
          <g fillOpacity="1" stroke="none">
            <text
              fill="#fff"
              strokeWidth="1.001"
              fontFamily="Rubik"
              fontSize="170.667"
              fontStretch="normal"
              fontStyle="normal"
              fontVariant="normal"
              fontWeight="normal"
              transform="matrix(1 0 0 .99745 1922.075 1003.017)"
              xmlSpace="preserve"
              style={{
                lineHeight: '1.25',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontVariantEastAsian: 'normal',
                whiteSpace: 'pre',
              }}
            >
              <tspan x="602.857" y="713.137">
                <tspan
                  style={{
                    fontVariantLigatures: 'normal',
                    fontVariantCaps: 'normal',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                  }}
                >
                  izedsystems
                </tspan>
              </tspan>
            </text>
            <path
              fill="url(#linearGradient937)"
              fillRule="evenodd"
              strokeLinecap="square"
              strokeLinejoin="round"
              strokeWidth="128"
              d="M3598.067 1810.676v-314.4h-314.4v32h282.4v282.4z"
            />
            <path
              fill="url(#linearGradient1875-7)"
              fillRule="evenodd"
              strokeLinecap="square"
              strokeLinejoin="round"
              strokeWidth="128"
              d="M2440.367 1528.276v314.4h314.4v-32h-282.4v-282.4z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVGizedsystemsABLogoDark;
