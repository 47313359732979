import React from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
import Company from '../../data/company.json';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      gap: theme.spacing(6),
      marginTop: theme.spacing(16),
    },
    [theme.breakpoints.up('lg')]: {
      gap: theme.spacing(8),
    },
  },
  paper: {
    padding: theme.spacing(2),
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));

const ContactView: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper
        elevation={4}
        className={classes.paper}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold' }}
          gutterBottom
        >
          Ring oss
        </Typography>
        <Typography variant="body1">
          <a
            href={`tel:${Company.contact.phone}`}
            className={classes.link}
          >
            {Company.contact.phone}
          </a>
          <br />
          <br />
          Bästa och snabbaste sättet att nå oss på är att ringa. Vi tar emot samtal kl. 8-18 varje dag inklusive helger.
        </Typography>
      </Paper>
      <Paper
        elevation={4}
        className={classes.paper}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold' }}
          gutterBottom
        >
          Mejla oss
        </Typography>
        <Typography variant="body1">
          <a
            href={`mailto:${Company.contact.email}`}
            className={classes.link}
          >
            {Company.contact.email}
          </a>
          <br />
          <br />
          Frågor, funderingar, eller beställningar går även bra att mejla oss om.
          <br />
          <br />
          För fakturor eller annat ekonomiskt, använd gärna
          <a
            href={`mailto:${Company.contact.economyEmail}`}
            className={classes.link}
          >
            {` ${Company.contact.economyEmail}.`}
          </a>
        </Typography>
      </Paper>
    </div>
  );
};

export default ContactView;
