import { createTheme } from '@mui/material/styles';
import { Theme, ThemeOptions } from '@mui/material';

export const SCROLLBAR_COLOR_THUMB = 'rgba(0, 0, 0, 0.4)';
export const SCROLLBAR_COLOR_THUMB_HOVER = 'rgba(0, 0, 0, 0.5)';
export const SCROLLBAR_COLOR_TRACK = '#eeeeee';
export const SCROLLBAR_SIZE_WEBKIT = { width: 8, height: 8 };
export const SCROLLBAR_SIZE_MOZ = { width: 'thin', height: 'thin' };

// Fix missing 'spacing' property on DefaultTheme
declare module '@mui/private-theming' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const CommonTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#533AC5',
      light: '#3A67C5',
      dark: '#050E93',
    },
    secondary: {
      main: '#C53A67',
      light: '#FC6D95',
      dark: '#90003D',
    },
  },
  typography: {
    fontFamily: '"Rubik", "Noto Sans", Arial, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: SCROLLBAR_SIZE_WEBKIT.width,
          height: SCROLLBAR_SIZE_WEBKIT.height,
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: SCROLLBAR_COLOR_TRACK,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: SCROLLBAR_COLOR_THUMB,
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: SCROLLBAR_COLOR_THUMB_HOVER,
        },
        // ===== Firefox =====
        '*': {
          scrollbarColor: `${SCROLLBAR_COLOR_THUMB} ${SCROLLBAR_COLOR_TRACK}`,
          scrollbarWidth: SCROLLBAR_SIZE_MOZ.width,
          // This property doesn't actually exist yet, although if mozilla eventually decides to
          // add it, this line got it covered so we avoid any potential "quick-fixes" later
          // down the line.
          scrollbarHeight: SCROLLBAR_SIZE_MOZ.height,
        },
      },
    },
  },
};

export const LightTheme = createTheme({
  ...CommonTheme,
  palette: {
    ...CommonTheme.palette,
    mode: 'light',
    background: {
      paper: '#FFFFFF',
      default: '#E7EBF0',
    },
  },
});

export const DarkTheme = createTheme({
  ...CommonTheme,
  palette: {
    ...CommonTheme.palette,
    mode: 'dark',
    background: {
      paper: '#161616',
      default: '#0A0A0A',
    },
  },
});
