import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from './ThemeProvider';

const AppProvider: React.FC = ({ children }) => (
  <ThemeProvider
    darkMode={false}
  >
    <BrowserRouter>
      {children}
    </BrowserRouter>
  </ThemeProvider>
);

export default AppProvider;
