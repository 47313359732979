import React from 'react';
import { makeStyles } from '@mui/styles';
import HeaderSection from './HeaderSection';
import CoreValuesSection from './CoreValuesSection';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
}));

const HomeView: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderSection />
      <CoreValuesSection />
    </div>
  );
};

export default HomeView;
