import React, { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, ButtonProps } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: `${theme.spacing(0.5)} !important`,
  },
  marginRight: {
    marginRight: `${theme.spacing(0.5)} !important`,
  },
  marginBoth: {
    marginLeft: `${theme.spacing(0.5)} !important`,
    marginRight: `${theme.spacing(0.5)} !important`,
  },
}));

interface UIButtonProps extends ButtonProps {
  margin?: 'left' | 'right' | 'both';
}

const UIButton = forwardRef<HTMLButtonElement, UIButtonProps>((props, ref) => {
  const {
    margin,
    className,
    ...otherProps
  } = props;
  const classes = useStyles();
  return (
    <Button
      ref={ref}
      variant="contained"
      size="small"
      className={clsx(className, {
        [classes.marginLeft]: margin === 'left',
        [classes.marginRight]: margin === 'right',
        [classes.marginBoth]: margin === 'both',
      })}
      {...otherProps}
    />
  );
});

export default UIButton;
