import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Company from '../../data/company.json';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(12),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(1),
    },
  },
  info: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(8),
    },
  },
}));

const UIFooter: React.FC = () => {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <footer className={classes.root}>
      <div className={classes.info}>
        <div>
          <Typography gutterBottom variant="subtitle2">Adress</Typography>
          <Typography variant="body2">
            {Company.name}
            <br />
            {Company.address.address}
            <br />
            {`${Company.address.zip} ${Company.address.county}`}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="subtitle2">Kontakt</Typography>
          <Typography variant="body2">
            {Company.contact.phone}
            <br />
            {Company.contact.email}
            <br />
            {Company.contact.website}
          </Typography>
        </div>
        <div>
          <Typography gutterBottom variant="subtitle2">Bolagsinfo</Typography>
          <Typography variant="body2">
            {`Org.nr ${Company.general.orgNumber}`}
            <br />
            {`VAT ${Company.general.vat}`}
            <br />
            {`Registrerat ${Company.general.founded}`}
          </Typography>
        </div>
      </div>
      <Typography variant="caption">
        &copy;&nbsp;
        {`${year} izedsystems AB`}
      </Typography>
    </footer>
  );
};

export default UIFooter;
