import React from 'react';
import { makeStyles } from '@mui/styles';
import { UIMutatingTypography } from '../../components/typography';
import { useIsMobile } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    minHeight: '4ems',
    margin: theme.spacing(8, 0),
    overflowX: 'hidden',
  },
}));

const MUTATING_TEXTS = [
  'skräddarsydda system',
  'blixtsnabb support',
  'trevlig support',
  'utmärkt support',
  'bra kvalité till lågt pris',
  'komplexa krav',
  'nytt transportsystem',
  'nytt demosystem',
  'nytt lagersystem',
  'moderna krav',
  'modernt system',
  'snabb leverans',
  'korta leveranstider',
  'snabba ändringar',
];

const HeaderSection: React.FC = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <section className={classes.root}>
      <UIMutatingTypography
        variant={isMobile ? 'h5' : 'h2'}
        texts={MUTATING_TEXTS}
      />
    </section>
  );
};

export default HeaderSection;
