import React, { HTMLAttributes } from 'react';

// https://svg2jsx.com/

const SVGizedsystemsABSquareLogo: React.FC<HTMLAttributes<HTMLOrSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    version="1.1"
    viewBox="0 0 346.4 346.4"
    {...props}
  >
    <defs>
      <linearGradient id="linearGradient1834">
        <stop offset="0" stopColor="#3a67c5" stopOpacity="1" />
        <stop offset="1" stopColor="#533ac5" stopOpacity="0.992" />
      </linearGradient>
      <linearGradient
        id="linearGradient937"
        x1="2440.367"
        x2="2722.756"
        y1="1842.676"
        y2="1560.287"
        gradientTransform="rotate(180 2668.467 1636.076)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
        xlinkHref="#linearGradient1834"
      />
      <linearGradient
        id="linearGradient937-3"
        x1="2440.367"
        x2="2722.756"
        y1="1842.676"
        y2="1560.287"
        gradientTransform="translate(109.8 -66.8)"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
        xlinkHref="#linearGradient1834"
      />
    </defs>
    <g transform="translate(-455.966 -894.8)">
      <g transform="translate(-2040.201 -633.676)">
        <g transform="translate(0 44)">
          <g
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="128"
            transform="translate(-54 55)"
          >
            <path
              fill="url(#linearGradient937)"
              d="M2896.567 1711.876v-282.4h-282.4v64h218.4v218.4z"
            />
            <path
              fill="url(#linearGradient937-3)"
              d="M2550.167 1493.476v282.4h282.4v-64h-218.4v-218.4z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVGizedsystemsABSquareLogo;
