import React, { useLayoutEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { SVGizedsystemsABSquareLogo } from '../icons';
import './UIStartupOverlay.css';

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    animation: 'fade-out-overlay 0.5s 1.25s ease-in forwards',
  },
  logo: {
    width: '10%',
    animation: 'fade-in-logo 0.75s ease, fade-out-logo 0.25s 1.25s ease-in forwards',
  },
}));

const LOCALSTORAGE_KEY = 'startup-shown';

const UIStartupOverlay: React.FC = ({ children }) => {
  const classes = useStyles();

  const [show, setShow] = useState(localStorage.getItem(LOCALSTORAGE_KEY) !== 'true');

  const overlayRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.addEventListener('animationend', (e) => {
        if (e.target === overlayRef.current) {
          setShow(false);
          localStorage.setItem(LOCALSTORAGE_KEY, 'true');
        }
      });
    }
  }, []);

  return (
    <>
      {
        show && (
          <div
            ref={overlayRef}
            className={classes.overlay}
          >
            <SVGizedsystemsABSquareLogo className={classes.logo} />
          </div>
        )
      }
      {!show && children}
    </>
  );
};

export default UIStartupOverlay;
