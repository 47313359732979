import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
import { UIButton } from '../../components/buttons';
import C from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    gap: theme.spacing(8),
  },
  cards: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(6),
    },
    [theme.breakpoints.up('xl')]: {
      gap: theme.spacing(12),
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  paperContainer: {
    width: '90%',
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-start',
      width: '40%',
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

const Card: React.FC<{
    title: ReactNode;
    description: ReactNode;
}> = ({ title, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.paperContainer}>
      <Paper
        className={classes.paper}
        elevation={4}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold' }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Paper>
    </div>
  );
};

const CustomView: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <div className={classes.cards}>
        <Card
          title="Flexibelt utefter dina önskemål"
          description="Bygg det drömsystem ditt företag behöver utefter just den kravspecifikation som ni har - oavsett komplexitet."
        />
        <Card
          title="Lösning för ditt företag"
          description="Ett unikt system för ditt företag kräver en unik lösning för just er situation; varför inte välja de bästa i branschen?"
        />
        <Card
          title="Bra kvalité"
          description={`\
Högkvalitativa system kräver skickliga utvecklare som har år av erfarenhet inom branschen - något som izedsystems erbjuder i mängd!`}
        />
      </div>
      <div className={classes.actions}>
        <UIButton variant="contained" size="large" onClick={() => navigate(C.ROUTE_PATH_CONTACT)}>
          Beställ idag
        </UIButton>
      </div>
    </div>
  );
};

export default CustomView;
